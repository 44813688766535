<template>
  <div>
    <!-- Page Title Start -->
    <div class="page-title title-bg-10">
      <div class="container">
        <div class="title-text text-center">
          <h2>{{ $t('navbar.docu') }}</h2>
          <!-- <h2 style="font-size:30px;">
            {{ typeDocumentation.libelle }}
          </h2> -->
        </div>
      </div>
    </div>
    <div class="choose-style-two choose-style-three pb-2 pt-4">
      <div class="container">
        <b-row>
          <b-col md="12">
            <b-card no-body>
              <b-card-body>
                <b-row>
                  <b-col md="2">
                    <div>
                      <label for="date-debut">{{ $t('documentheque.dte_debut') }}</label>
                      <b-form-datepicker
                        id="date-debut"
                        v-model="form.date_debut"
                        placeholder=""
                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                        locale="fr"
                      />

                    </div>
                  </b-col>
                  <b-col md="2">
                    <div>
                      <label for="date-fin">{{ $t('documentheque.dte_fin') }}</label>
                      <b-form-datepicker
                        id="date-fin"
                        v-model="form.date_fin"
                        placeholder=""
                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                        locale="fr"
                      />
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div>
                      <!-- basic -->
                      <b-form-group
                        :label="$t('documentheque.keyword')"
                        label-for="mot-cle"
                      >
                        <b-form-input
                          id="mot-cle"
                          v-model="form.search"
                          placeholder=""
                        />
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div>
                      <!-- basic -->
                      <b-form-group
                        :label="$t('documentheque.categorie')"
                        label-for="categorie"
                      >
                        <v-select
                          id="categorie"
                          v-model="form.type"
                          label="libelle"
                          :options="categorieDocumentations"
                          :placeholder="$t('documentheque.dte_debut')"
                          :reduce="(categorieDocumentations) => categorieDocumentations.id"
                          class="select-size-sm"
                        />
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="2">
                    <div>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="primary"
                        class="mt-2 float-right"
                        pill
                        @click="search"
                      >
                        <span class="align-middle">{{ $t('documentheque.search') }}</span>
                        <feather-icon
                          icon="SearchIcon"
                          class="ml-50"
                        />
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="choose-style-two choose-style-three pb-4">
      <div class="container">
        <b-row class="justify-content-center">
          <b-col md="8">
            <b-card
              v-for="Doc in documentations"
              :key="Doc.libelle"
            >
              <b-card-body>
                <b-media no-body>
                  <b-media-aside>
                    <b-img
                      :src="require('@/assets/images/doc.png')"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                    />
                  </b-media-aside>
                  <b-media-body class="ml-1">
                    <h5 class="mt-0">
                      {{ Doc.libelle }}
                    </h5>
                    <b-card-text> {{ Doc.description }}</b-card-text>
                    <b-card-text>
                      <span><b-badge variant="light-success">
                        {{ $t('documentheque.publie_le') }} {{ Doc.created_at }}
                      </b-badge></span>
                      <b-button
                        v-if="$i18n.locale === 'en'"
                        v-b-modal.modal-prevent-document-closing
                        variant="flat-danger"
                        size="sm"
                        pill
                        class="ml-2 float-right"
                        @click="sendLink(Doc,'en')"
                      >
                        <feather-icon icon="EyeIcon" />
                        {{ $t('see') }}
                      </b-button>
                      <b-button
                        v-if="$i18n.locale === 'fr'"
                        v-b-modal.modal-prevent-document-closing
                        variant="flat-danger"
                        size="sm"
                        pill
                        class="ml-2 float-right"
                        @click="sendLink(Doc,'fr')"
                      >
                        <feather-icon icon="EyeIcon" />
                        {{ $t('see') }}
                      </b-button>
                      <b-button
                        v-if="$i18n.locale === 'fr'"
                        :href="Doc.document_fr_url"
                        target="_blank"
                        size="sm"
                        class="float-right"
                        download
                        pill
                        variant="flat-primary"
                      >
                        <feather-icon icon="DownloadIcon" />
                        {{ $t('documentheque.download') }}
                      </b-button>
                      <b-button
                        v-if="$i18n.locale === 'en'"
                        :href="Doc.document_en_url"
                        target="_blank"
                        size="sm"
                        class="float-right"
                        download
                        pill
                        variant="flat-primary"
                      >
                        <feather-icon icon="DownloadIcon" />
                        {{ $t('documentheque.download') }}
                      </b-button>
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-card-body>
            </b-card>
            <b-card
              v-if="!documentations.length"
            >
              <b-card-body>
                <p style="text-align:center">
                  {{ $t('documentheque.notification') }}
                </p>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal
      id="modal-prevent-document-closing"
      ref="my-modal"
      size="xl"
      button-size="sm"
      :title="documentName"
      ok-only
      ok-title="Fermer"
    >
      <iframe
        :src="documentFile"
        style="width: 100%;height: 100vh; border: none;"
      >
        Problème de chargement du document.
      </iframe>
    </b-modal>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'

// eslint-disable-next-line import/no-cycle
import {
  ref, onMounted, reactive, computed,
} from '@vue/composition-api'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import useTypeDocumentation from '@/services/admin/categorieDocumentationService'
// eslint-disable-next-line import/no-cycle
import useDocumentation from '@/services/admin/documentationService'
// import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { French } from 'flatpickr/dist/l10n/fr'
// eslint-disable-next-line import/no-cycle
import useDocumentationUpload from '@/services/upload/useDocumentationUpload'
import dayjs from 'dayjs'
import {
  BRow, BCol, BCard, BCardText, BFormGroup, BButton, BCardBody, BFormInput, BModal, BFormDatepicker,
  BMedia, BImg, BMediaAside, BMediaBody, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormDatepicker,
    BCardText,
    BFormGroup,
    BFormInput,
    BButton,
    BCardBody,
    BModal,
    BMedia,
    vSelect,
    BImg,
    BMediaAside,
    BMediaBody,
    BBadge,
    // Navbar Components
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      filtered: [],
      documentFile: '',
      documentName: '',
    }
  },
  created() {
  },
  methods: {
    sendLink(item, lang) {
      // eslint-disable-next-line no-unused-expressions
      lang === 'fr' ? this.documentFile = item.document_fr_url : this.documentFile = item.document_en_url
      this.documentName = item.libelle
    },
    getDocumentationV(data) {
      this.filtered = this.documentations
        .filter(documentation => documentation.type_documentation != null && documentation.type_documentation.libelle.toUpperCase() === data.toUpperCase())
    },
  },

  setup() {
    const {
      categorieDocumentations, getTypeDocumentations,
    } = useTypeDocumentation()
    const getFileLink = async data => {
      // eslint-disable-next-line no-use-before-define
      await downloadFile(data)
    }
    let filteredDocumentation = []
    const getDocumentation = async data => {
      // eslint-disable-next-line no-use-before-define
      filteredDocumentation = documentations.value
        .filter(documentation => documentation.type_documentation != null && documentation.type_documentation.libelle.toUpperCase() === data.toUpperCase())
      // eslint-disable-next-line no-use-before-define
      return documentations
    }

    const form = reactive({
      date_debut: '',
      date_fin: '',
      search: null,
      type: 1,
      pagesize: 10,
    })
    const config = {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: 'j M  Y',
      altInput: true,
      dateFormat: 'Y-m-d',
      locale: French, // locale for this instance only
    }
    const {
      documentations, searchDocuments, getDocumentations, errors, documentationProcess, loader,
    } = useDocumentation()

    const results = ref()
    const search = async () => {
      await searchDocuments({ ...form })
      console.log(documentations)
    }
    const {
      fileLink, downloadFile,
    } = useDocumentationUpload()
    onMounted(
      async () => {
        await getDocumentations()
        await getTypeDocumentations()
        // form.type = categorieDocumentations.value[0].id
        await searchDocuments({ ...form })
        form.date_debut = dayjs().format()
      },
    )
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const fields = reactive([
      { key: 'index', label: 'N°' },
      { key: 'libelle', label: 'Libellé', sortable: true },
      { key: 'description', label: 'Description', sortable: true },
      { key: 'type_documentation.libelle', label: 'Type', sortable: true },
      { key: 'actions' },
    ])

    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }
    const sortOptions = computed(() => {
      fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    })
    return {
      categorieDocumentations,
      getTypeDocumentations,
      errors,
      documentations,
      getDocumentation,
      getDocumentations,
      documentationProcess,
      perPage,
      pageOptions,
      totalRows,
      searchDocuments,
      currentPage,
      downloadFile,
      getFileLink,
      filteredDocumentation,
      fileLink,
      sortBy,
      form,
      results,
      sortDesc,
      config,
      sortDirection,
      filter,
      filterOn,
      fields,
      loader,
      search,
      onFiltered,
      sortOptions,
    }
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
