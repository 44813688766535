import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'

import useAlertNotification from '@/services/notification/useAlert'

export default function useDocumentations() {
  const { errorToast, successToast } = useAlertNotification()
  const documentation = ref([])
  const loader = ref(false)
  const documentationProcess = ref(false)
  const documentationSuccess = ref(false)
  const documentations = ref([])
  const errors = ref('')

  // Liste des documentations
  const getDocumentations = async () => {
    loader.value = true
    await axiosClient.get('/documentations').then(response => {
      if (response.data.success === true) {
        loader.value = false
        documentations.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  const searchDocuments = async data => {
    loader.value = true
    try {
      const response = await axiosClient.post('/search-document', data)
      if (response.data.success === true) {
        loader.value = false
        console.log(response)
        documentations.value = response.data.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  // Obtenir une documentation
  const getDocumentation = async slug => {
    const response = await axiosClient.get(`/documentations/${slug}`)
    if (response.data.success === true) {
      successToast('Enregistrement', response.data.message)
      documentationSuccess.value = true
      documentationProcess.value = false
      getDocumentations()
    }
  }

  // Supprimer une documentation
  const deleteDocumentation = async slug => {
    await axiosClient.delete(`/documentations/${slug}`).then(response => {
      if (response.data.success === true) {
        successToast('Supression', response.data.message)
        documentationSuccess.value = true
        documentationProcess.value = false
        getDocumentations()
      }
    })
      .catch(error => {
        documentationProcess.value = false
        documentationSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  // Ajouter une documentation
  const createDocumentation = async data => {
    errors.value = ''
    documentationProcess.value = true
    await axiosClient.post('/documentations', data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Enregistrement', response.data.message)
          documentationSuccess.value = true
          documentationProcess.value = false
          getDocumentations()
        }
      })
      .catch(error => {
        documentationProcess.value = false
        documentationSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          // errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
          const createUserErrors = error.response.data.errors

          Object.keys(createUserErrors).forEach(key => {
            errorToast('Oups! Erreur', createUserErrors[key][0])
          })
        }
      })
  }

  // Modifier une documentation
  const updateDocumentation = async data => {
    errors.value = ''
    console.log(data.slug)
    documentationProcess.value = true
    await axiosClient.patch(`/documentations/${data.slug}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Modification', response.data.message)
          documentationSuccess.value = true
          documentationProcess.value = false
          getDocumentations()
        }
      })
      .catch(error => {
        documentationProcess.value = false
        documentationSuccess.value = false

        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  const translateDocumentation = async data => {
    console.log(data)
    const res = await axiosClient.post(
      'https://translation.googleapis.com/language/translate/v2?key=AIzaSyCqvGmpX43LzXginjCDVAfNd7zHsMv1xZA',
      { q: data, target: 'en' },
    )
    const translation = res.data.data.translations[0].translatedText
    console.log(translation)
    return translation
  }

  return {
    errors,
    documentation,
    documentations,
    searchDocuments,
    getDocumentations,
    translateDocumentation,
    deleteDocumentation,
    documentationProcess,
    createDocumentation,
    updateDocumentation,
    getDocumentation,
    loader,
    documentationSuccess,
  }
}
