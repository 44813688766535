import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'

import useAlertNotification from '@/services/notification/useAlert'

export default function useTypeDocumentations() {
  const { errorToast, successToast } = useAlertNotification()
  const categorieDocumentation = ref([])
  const loader = ref(false)
  const Process = ref(false)
  const Success = ref(false)
  const categorieDocumentations = ref([])
  const errors = ref('')

  // Liste des documentations
  const getTypeDocumentations = async () => {
    loader.value = true
    await axiosClient.get('/type_documentations').then(response => {
      if (response.data.success === true) {
        loader.value = false
        categorieDocumentations.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  // Obtenir un categorieDocumentation
  const getTypeDocumentation = async slug => {
    const response = await axiosClient.get(`/type_documentations/${slug}`)
    categorieDocumentation.value = response.data.data
  }
  // Ajouter une catégorie de documentation
  const createTypeDocumentation = async data => {
    errors.value = ''
    Process.value = true
    await axiosClient.post('/type_documentations', data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Enregistrement', response.data.message)
          Success.value = true
          Process.value = false
          getTypeDocumentations()
        }
      })
      .catch(error => {
        Process.value = false
        Success.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  // Modifier une catégorie de documentation
  const updateTypeDocumentation = async data => {
    errors.value = ''
    Process.value = true
    await axiosClient.patch(`/type_documentations/${data.slug}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Modification', response.data.message)
          Success.value = true
          Process.value = false
          getTypeDocumentations()
        }
      })
      .catch(error => {
        Process.value = false
        Success.value = false

        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  // Supprimer une documentation
  const deleteTypeDocumentation = async slug => {
    await axiosClient.delete(`/type_documentations/${slug}`).then(response => {
      if (response.data.success === true) {
        successToast('Supression', response.data.message)
        Success.value = true
        Process.value = false
        getTypeDocumentations()
      }
    })
      .catch(error => {
        Success.value = false
        Process.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  return {
    errors,
    categorieDocumentation,
    categorieDocumentations,
    getTypeDocumentations,
    Process,
    createTypeDocumentation,
    updateTypeDocumentation,
    deleteTypeDocumentation,
    getTypeDocumentation,
    loader,
    Success,
  }
}
