/* eslint-disable no-shadow */
/* eslint-disable prefer-destructuring */
import { ref, watch } from '@vue/composition-api'
import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'

export default function useDocumentationUpload() {
  const documentationFile = ref('')
  const documentationUrl = ref('')
  const fileName = ref('')
  const fileLink = ref('')
  const errors = ref([])
  const { errorToast } = useAlertNotification()

  const uploadFile = async data => {
    await axiosClient.post('/upload-documentation', data)
      .then(response => {
        fileName.value = response.data.pdfFile
        console.log(response.data)
        // successToast('Enregistrement', 'Fichier chargé avec succès')
      })
      .catch(error => {
        errors.value = ''
        errors.value = error.response.data.errors
        const uploadFileErrors = error.response.data.errors
        Object.keys(uploadFileErrors).forEach(key => {
          errorToast('Oups! Erreur', uploadFileErrors[key][0])
        })
      })
  }
  const downloadFile = async data => {
    await axiosClient.get(`/download-documentation/${data}`)
      .then(response => {
        console.log(response)
        const blob = new Blob([response.data])
        fileLink.value = window.URL.createObjectURL(blob)
        // successToast('Enregistrement', 'Fichier chargé avec succès')
      })
      .catch(error => {
        errors.value = ''
        errors.value = error.response.data.errors
        const uploadFileErrors = error.response.data.errors
        Object.keys(uploadFileErrors).forEach(key => {
          errorToast('Oups! Erreur', uploadFileErrors[key][0])
        })
      })
  }
  const deleteFile = async data => {
    await axiosClient.delete(`/remove-documentation/${data}`)
  }

  const handleDocumentationSelected = event => {
    if (event.target.files.length === 0) {
      documentationFile.value = ''
      documentationUrl.value = ''
      return
    }

    documentationFile.value = event.target.files[0]
  }

  watch(documentationFile, documentationFile => {
    if (!(documentationFile instanceof File)) {
      return
    }

    const fileReader = new FileReader()
    console.log(documentationFile)
    fileReader.readAsDataURL(documentationFile)

    fileReader.addEventListener('load', () => {
      documentationUrl.value = fileReader.result
    })
  })

  return {
    documentationFile,
    documentationUrl,
    handleDocumentationSelected,
    uploadFile,
    downloadFile,
    fileLink,
    fileName,
    deleteFile,
  }
}
